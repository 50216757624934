import {
  components,
} from '@/types/openapi.ts';

export enum EModelLifecycleState {
    REGISTERED = 0,
    LOADING_DATA = 10,
    PREPARING_DATA = 20,
    TRAINING = 30,
    SAVING_MODEL = 40,
    CREATING_METADATA = 50,
    READY = 100,
}

// following map ensures that we have a entry in the enum for each available member
export const ModelLifecycleStateMap: Record<components['schemas']['ModelLifecycleState'], keyof typeof EModelLifecycleState> = {
  0: 'REGISTERED',
  10: 'LOADING_DATA',
  20: 'PREPARING_DATA',
  30: 'TRAINING',
  40: 'SAVING_MODEL',
  50: 'CREATING_METADATA',
  100: 'READY',
};
