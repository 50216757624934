import {
  dateStringToSeconds,
} from '../utils/date.js';
import {
  scaleFurnaces,
} from './inputCleaning.js';

export const create = (apiClient) => {
  const {
    fetchGraphJSON,
  } = apiClient;

  const getShopLayouts = async (location) => {
    const query = `
    query {
      shopLayoutMenuItems {
        id
        name
        description
      }
    }`;

    let res = await fetchGraphJSON(query, {
      location,
    });
    res = res.data.shopLayoutMenuItems;
    return res;
  };

  const ShopLayoutViewUpdatableFields = `
    recipeHeader {
      loadIdentifier
      loadName,
      recipeName,
      versions {
        versionText
        startingTime
      }
    }
    systemStatus {
      entranceReady
      exitReady
    }
    place {
      id
      xPos
      yPos
      xSize
      ySize
      layoutOrder
      colorPalette
      colorIndex
      colorBG
      systemColorBG
      name
    }
    loadButton {
      id
      type
      xPos
      yPos
      colorIndex
      xSize
      ySize
      chamberId
    }
    value {
      id,
      type,
      value,
      status,
      digitsBeforeComma,
      digitsAfterComma,
      textBefore,
      textAfter,
      xPos,
      yPos,
      colorIndex,
      colorBG,
      numberFormat,
      fontSize
    }
    gif {
      id
      type
      xPos
      yPos
      filePath
    }
    label {
      id
      type
      text
      xPos
      yPos
      colorIndex
      colorBG
      fontSize
    }
    bitmap {
      id
      type
      filePath
      xPos
      yPos
      colorIndex
      colorBG
    }
    line {
      id
      type
      xPos
      yPos
      colorIndex
      width
      height
    }
    labelPlus {
      id
      type
      xPos
      yPos
      colorIndex
      colorBG
      text
      fontSize
    }
    editBox {
      id
      type
      xPos
      yPos
      colorIndex
      colorBG
      xSize
      ySize
      focusOrderNumber
      variableType
      isEditable
      editValue
      digitsBeforeComma
      digitsAfterComma
      minValue
      maxValue
      numberFormat
      fontSize
      componentTypeId
      userInputId
      outputId
    }
    rectangle {
      id
      type
      xPos
      yPos
      colorIndex
      colorBG
      xSize
      ySize
    }
    progressBar {
      id
      type
      xPos
      yPos
      xSize
      ySize
      fullColor
      emptyColor
      colorBG
      minValue
      maxValue
      value
    }
    chargeCar {
      id
      type
      carType
      xPos
      yPos
      xSize
      ySize
      layoutOrder
      imageName
      car {
        wpId
        name
        infoText
        placeId
        placeName
        placeColor
        placeColorBG
        posX
      }
      carList {
        wpId
        name
        infoText
        placeId
        placeName
        placeColor
        placeColorBG
        posX
      }
      railMaxPosPixels
      railMaxPosMm
      mode
      autFurnaceDriving {
        driverWpId
        overWpId
        furnaceWidth
        furnaceHeight
        furnaceOverviewNo
        furnacePlace
      }
    }
  `;

  const getShopLayoutItems = async (location, shopId) => {
    const query = `
    query {
      shopLayoutView(shopLayoutId: ${shopId}) {
        id
        workplaceId
        type
        name
        xPos
        yPos
        xSize
        ySize
      }
    }`;

    try {
      let res = await fetchGraphJSON(query, {
        location,
      });
      res = res?.data?.shopLayoutView;
      res = scaleFurnaces(res);
      return res;
    } catch (err) {
      return err;
    }
  };

  const getAllShoplayoutsItems = async (location, cb) => {
    try {
      const layouts = await getShopLayouts(location);
      if (Array.isArray(layouts) && layouts.length > 0) {
        for (const layout of layouts) {
          // eslint-disable-next-line no-await-in-loop
          layout.data = await getShopLayoutItems(location, layout.id);
        }
      }
      cb(layouts);
    } catch (err) {
      cb([]);
    }
  };

  const updateShopLayoutValues = async (location, shopLayoutId) => {
    const query = `
          query {
  updateShopLayoutValues(shopLayoutId: ${shopLayoutId}) {
    id
    type
    workplaceId
    state
    name
    ${ShopLayoutViewUpdatableFields}
  }
}`;
    let res = await fetchGraphJSON(query, {
      location,
    });
    res = res.data.updateShopLayoutValues;
    return res;
  };

  function getShopLayoutAlarms(location, shopLayoutId) {
    const query = `
  query{
    shopLayoutAlarms(shopLayoutId: ${shopLayoutId}) {
      alarmId
      workplaceName
      startTime
      acknowledgeTime
      endTime
      priority
      alarmMessage
      gate
      alarmNum
      byte
      bit
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function getShopLayoutAlarmsSearch(
    location,
    {
      shopLayoutId, startTime, endTime,
    },
  ) {
    const query = `
  query{
    shopLayoutAlarmsSearch(shopLayoutId: ${shopLayoutId}, startTime: ${dateStringToSeconds(
  startTime,
)}, endTime: ${dateStringToSeconds(endTime)}) {
      workplaceName
      time
      event
      alarmText
      alarmNum
    }
  }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  function listOfAvailableFurnaces(location) {
    const query = `
    query {
      listOfAvailableFurnaces
    }`;
    return fetchGraphJSON(query, {
      location,
    });
  }

  return {
    getAllShoplayoutsItems,
    getShopLayoutAlarms,
    getShopLayoutAlarmsSearch,
    getShopLayouts,
    updateShopLayoutValues,
    listOfAvailableFurnaces,
  };
};
