export default {
  title: 'Model training',
  subtitle: 'Erstelle ein neues Machinelearning Model basierend auf anderen Sensordaten.',
  saveAction: 'Model trainieren',
  selectTargetHeadline: 'Targetsensor',
  selectFeaturesHeadline: 'Feature-Sensoren',
  selectTrainingData: 'Trainingsdaten',
  configureModel: 'Modelkonfiguration',
  modelDisplayName: 'Modelname',
};
