export default {
  reportBuilder: 'Berichtsersteller',
  subheadline: 'Diese Einstellungen definieren das globale Erscheinungsbild von von Qmulus generierten PDFs.',
  reportForm: {
    size: {
      label: 'Standardpapiergröße',
      tooltip: 'Ermöglicht Ihnen die Auswahl der Papiergröße.',
    },
    language: {
      label: 'Sprache',
      tooltip: 'Ermöglicht Ihnen die gewünschte Sprache auszuwählen.',
    },
    logo: {
      label: 'Logo',
      tooltip: 'Ermöglicht Ihnen das Hochladen eines Logos.',
    },
  },
};
