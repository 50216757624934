// eslint-disable-next-line max-classes-per-file
import {
  getHexColor,
} from '../utils/colors.js';

const scaleItems = (items, scalingFactor) => {
  for (const [
    , item,
  ] of items.entries()) {
    for (const [
      prop,
      value,
    ] of Object.entries(item)) {
      if (typeof value === 'number' && prop !== 'id') {
        item[prop] = Math.ceil(value * scalingFactor);
      }
    }
  }

  return items;
};

export const scaleFurnaces = (itemsList) => {
  let machines = [];
  const smallestAllowed = {};
  smallestAllowed.width = 10;
  smallestAllowed.height = 50;
  let scalingFactor = 1;

  for (const item of itemsList) {
    if (item.type === 'Furnace') {
      const machine = {
        id: item.id,
        type: item.type,
        workplaceId: item.workplaceId,
        name: item.name,
        x: item.xPos,
        y: item.yPos,
        width: item.xSize,
        height: item.ySize,
        background: item.background,
        value: item.value,
        text: item.text,
        loadButton: item.loadButton,
        state: item.state,
        systemStatus: item.systemStatus,
        recipeHeader: item.recipeHeader,
        gif: item.gif,
        label: item.label,
        labelPlus: item.labelPlus,
        bitmap: item.bitmap,
        line: item.line,
        place: item.place,
        editBox: item.editBox,
        progressBar: item.progressBar,
        rectangle: item.rectangle,
        ...item,
      };
      machines.push(machine);

      for (const [
        prop,
        value,
      ] of Object.entries(machine)) {
        if (smallestAllowed[prop] && smallestAllowed[prop] > value) {
          const diff = smallestAllowed[prop] / value;
          if (diff > scalingFactor) {
            scalingFactor = diff;
          }
        }
      }
    } else if (item.type === 'ShopLayout') {
      machines.push({
        id: item.id,
        type: item.type,
        name: item.name,
        x: item.xPos,
        y: item.yPos,
        width: item.xSize,
        height: item.ySize,
        background: item.background,
        value: item.value,
        text: item.text,
        loadButton: item.loadButton,
        place: item.place,
        rectangle: item.rectangle,
        label: item.label,
        labelPlus: item.labelPlus,
        chargeCar: item.chargeCar,
        chargeStatusList: item.chargeStatusList,
        ...item,
      });
    }
  }

  machines = scaleItems(machines, scalingFactor);
  return machines;
};

export const scaleChartRecorder = (itemsList) => {
  const items = [];

  for (const chart of itemsList) {
    const item = {
      label: chart.chartName,
      data: chart.data?.map((data) => ({
        x: new Date(data.timestamp * 1000),
        y: Math.round(data.value),
      })),
      borderWidth: 1,
      backgroundColor: getHexColor(chart.chartColor),
      borderColor: getHexColor(chart.chartColor),
      color: getHexColor(chart.chartColor),
      unit: chart.chartUnit,
    };
    items.push(item);
  }

  return items;
};
