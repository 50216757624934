export default {
  reportBuilder: 'Report builder',
  subheadline: 'These settings define the global appearance of PDFs generated by Qmulus.',
  reportForm: {
    size: {
      label: 'Default paper size',
      tooltip: 'Allows you to select the paper size.',
    },
    language: {
      label: 'Language',
      tooltip: 'Allows you to select the desired language.',
    },
    logo: {
      label: 'Logo',
      tooltip: 'Allows you to upload logo.',
    },
  },
};
