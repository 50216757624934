export default {
  title: 'Modeltraining',
  subtitle: 'Build new machine learning models to predict sensor based on other available sensors.',
  saveAction: 'train model',
  selectTargetHeadline: 'Target signal',
  selectFeaturesHeadline: 'Feature signals',
  selectTrainingData: 'Training data',
  configureModel: 'Model configuration',
  modelDisplayName: 'Model name',
};
